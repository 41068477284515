<template>
    <!-- 收货信息 -->
    <card class="wrapper">
        <AdminTitle title="收货信息" size="20px"></AdminTitle>
        <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="contactName" label="联系人"></el-table-column>
            <el-table-column prop="contactMobile" label="联系电话"></el-table-column>
            <el-table-column prop="contactEmail" label="联系邮箱"></el-table-column>
            <el-table-column prop="detail" label="详细地址">
                <template slot-scope="{ row }">
                    {{ row.province + row.city + row.district + row.detail }}
                    <span
                        class="el-icon-edit"
                        @click="editUser"
                        v-show="isShowBtn(AUTH_BTN.review_edit_address)"
                    >
                    </span>
                </template>
            </el-table-column>
        </el-table>

        <!-- 编辑收货地址弹窗 -->
        <el-dialog
            title="编辑收货地址"
            :visible.sync="dialogVisibleUser"
            width="450px"
            :show-close="true"
        >
            <el-form ref="formUser" :model="userFormData" label-width="80px">
                <el-form-item label="联系人">
                    <el-input
                        style="width: 300px"
                        v-model="userFormData.contactName"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input
                        style="width: 300px"
                        v-model="userFormData.contactMobile"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系邮箱">
                    <el-input
                        style="width: 300px"
                        v-model="userFormData.contactEmail"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item label="所在地区">
                    <el-cascader
                        disabled
                        style="margin-right: 20px"
                        v-model="positionV"
                        size="mini"
                        :options="options"
                    ></el-cascader>
                    <el-link
                        @click="showMap"
                        type="primary"
                        :underline="false"
                        icon="el-icon-location"
                        >选择位置</el-link
                    >
                </el-form-item>
                <el-form-item label="详细地址">
                    <el-input
                        style="width: 300px"
                        type="textarea"
                        v-model="userFormData.detail"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="dialogVisibleUser = false" size="small">取 消</el-button>
                <el-button type="primary" @click="saveAddress" size="small">保 存</el-button>
            </div>
        </el-dialog>
        <!-- 地图 -->
        <el-dialog
            class="inner-dialog"
            width="50%"
            title=""
            :visible.sync="innerVisible"
            append-to-body
        >
            <template>
                <GdMap
                    ref="map"
                    :lng.sync="userFormData.lng"
                    :lat.sync="userFormData.lat"
                    :province.sync="address.province"
                    :city.sync="address.city"
                    :district.sync="address.district"
                ></GdMap>
            </template>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button @click="outerVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="innerVisible = false" size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </card>
</template>

<script>
import { updateOrderAddressAPI } from './api';
import GdMap from '@/components/GdMap/gd-map.vue';
import { authBtnMixin } from '@/mixins/authBtnMixin';
export default {
    name: 'Address',
    mixins: [authBtnMixin],
    components: { GdMap },
    props: {
        orderAddress: {
            type: Object,
            default: () => {},
        },
        status: {
            type: [Number, String],
        },
        flag: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            dialogVisibleUser: false,
            innerVisible: false,
            userFormData: {
                orderNo: this.$route.params.id,
                contactName: '',
                contactMobile: '',
                contactEmail: '',
                lng: '',
                lat: '',
                detail: '',
            },
            positionV: ['province', 'city', 'district'],
            address: {},
        };
    },
    watch: {
        orderAddress: {
            handler(newV, oldV) {
                this.address = { ...this.orderAddress };
            },
        },
    },
    computed: {
        tableData() {
            return this.orderAddress ? [this.orderAddress] : [];
        },
        options() {
            const { province, city, district } = this.address;
            let arr = [];
            arr = [
                {
                    value: 'province',
                    label: province,
                    children: [
                        {
                            value: 'city',
                            label: city || province,
                            children: [
                                {
                                    value: 'district',
                                    label: district,
                                },
                            ],
                        },
                    ],
                },
            ];
            return arr;
        },
    },
    mounted() {},

    methods: {
        // 编辑联系人
        editUser() {
            this.address = { ...this.orderAddress };
            this.dialogVisibleUser = true;
            const { contactName, contactMobile, contactEmail, lng, lat, detail } =
                this.orderAddress;

            this.userFormData = {
                orderNo: this.$route.params.id,
                contactName,
                contactMobile,
                contactEmail,
                lng: Number(lng),
                lat: Number(lat),
                detail,
            };
        },
        // 保存地址
        saveAddress() {
            updateOrderAddressAPI(this.userFormData).then(() => {
                this.$emit('update:flag', false);
                this.$emit('updateDetail');
                this.$message.success('更新成功');
                this.dialogVisibleUser = false;
            });
        },
        showMap() {
            this.innerVisible = true;
            this.$nextTick(() => {
                this.$refs.map.tipinput = '';
                setTimeout(() => {
                    this.$refs.map.initMap();
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
    width: 100%;
    border-color: transparent;
    margin-top: 20px;
    border-radius: 16px;
    color: #222222;
    .el-table__header .el-table__cell {
        font-weight: 600;
        color: #222222;
    }
    .store-box {
        padding: 10px;
        border: 1px solid #ebeef5;
        .el-descriptions {
            td,
            th {
                padding: 7px;
                text-align: center;
                font-size: 13px;
            }
        }
    }
    .el-descriptions {
        .my-label {
            color: #1a1e25;
            background: #dadada;
        }
        .my-content {
            .handle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                & > span {
                    &:first-child {
                        margin-right: 20px;
                        flex: 1;
                    }
                }
            }
        }
    }
    .contact-remark {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .item {
            background: #fafafa;
            flex: 1;
            border: 1px solid #ebeef5;
            padding: 5px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:first-child {
                margin-right: 20px;
            }
        }
    }
    .contact-remark-table {
        .header {
            margin-bottom: 10px;
            background: #fafafa;
            padding: 5px 10px;
            .handle-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left {
                    display: flex;
                    align-items: center;
                    flex: 1;
                    margin-right: 10px;
                    .el-input {
                        margin-right: 10px;
                        width: 50%;
                    }
                }
            }
        }
        .tp-table {
            .el-table__fixed-right {
                height: 100% !important;
            }
        }
    }
    .el-icon-edit {
        cursor: pointer;
        margin-left: 10px;
        color: #1483ea;
    }
}
</style>
