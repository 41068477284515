<template>
	<div class="wrapper-jishen">
		<card class="base-info-new">
			<div class="base-info-top">
				<div class="id-card-pic">
					<el-image
						style="
							width: 100px;
							border-radius: 8px;
							height: 100px;
							margin-right: 10px;
						"
						:src="member.identityFrontImageUrl"
						:preview-src-list="[member.identityFrontImageUrl]"
					>
						<div
							style="
								background: #f5f7fa;
								width: 100px;
								height: 100px;
								line-height: 100px;
								text-align: center;
								color: #c0c4cc;
							"
							slot="error"
						>
							暂无图片
						</div>
					</el-image>
					<el-image
						style="width: 100px; border-radius: 8px; height: 100px"
						:src="member.identityBackImageUrl"
						:preview-src-list="[member.identityBackImageUrl]"
					>
						<div
							style="
								background: #f5f7fa;
								width: 100px;
								height: 100px;
								line-height: 100px;
								text-align: center;
								color: #c0c4cc;
							"
							slot="error"
						>
							暂无图片
						</div>
					</el-image>
				</div>
				<div class="person-info">
					<div>
						<span class="name fw700">{{ member.memberName }}</span>
						<el-tag type="success">年龄：{{ member.age }}</el-tag>
						<el-tag type="success">性别：{{ member.genderName }}</el-tag>
						<span v-for="(item, i) in detail.rosterLevelList" :key="i">
							<span class="tag-ku t3" v-if="item == 1" style="margin: 2px"
								>白名单</span
							>
							<span class="tag-ku t1" v-if="item == 2" style="margin: 2px"
								><i class="el-icon-warning"></i>黑名单</span
							>
							<span class="tag-ku t2" v-if="item == 3" style="margin: 2px"
								><i class="el-icon-warning"></i>灰名单</span
							>
						</span>
					</div>
					<div>
						<span class="mr50">
              <span class="label">手机号：</span>
              <span class="fw700">{{ member.mobile }}</span>
            </span>
            <span>
              <span class="label">身份证号：</span>
              <span class="fw700">{{ member.identityNoPlain }}</span>
            </span>
					</div>
					<div>
						<span>
              <span class="label">户籍所在地：</span>
              <span class="fw700">{{ member.identityAddress || "" }}</span>
            </span>
					</div>
					<div class="rating" v-if="enableHitScore">
						<TagLvNew :name="detail.sysHitGrade ? detail.sysHitGrade : 'ZW'">
						</TagLvNew>
						<p>系统评级</p>
					</div>
				</div>
			</div>
		</card>

		<!-- 客户评级 -->
		<template v-if="enableHitScore">
      <LevelProgress
          :scoreProfileData="detail"
          :sysScore="detail.sysHitScore"
          :sysLevel="detail.sysHitGrade"
          :style="{ marginBottom: '20px' }"
      />
    </template>
    <template>
      <div style="margin-bottom: 20px;"></div>
    </template>

		<card class="card" v-if="enableHitScore">
			<AdminTitle title="系统评分详情" size="20px"></AdminTitle>
			<el-table :data="systemScoreDetail">
				<el-table-column label="序号" prop="index" width="120px"></el-table-column>
				<el-table-column
					label="评分项命中规则"
					prop="ruleName"
				></el-table-column>
				<el-table-column label="分值" prop="hitScore">
					<template slot-scope="{ row }">
						<span class="danger-cell">{{ row.hitScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="hitSuggestDesc">
					<template slot-scope="{ row }">
						<span class="danger-cell">{{ row.hitSuggestDesc }}</span>
					</template></el-table-column
				>
			</el-table>
		</card>
		<card class="card">
			<AdminTitle title="宝系智租风险评估" size="20px"></AdminTitle>
			<el-table :data="riskListTable">
				<el-table-column label="风险项" prop="riskName"></el-table-column>
				<el-table-column label="风险等级" prop="riskRank">
					<template slot-scope="{ row }">
						<div
							class="rank-tag"
							:style="{
								backgroundColor: rankToColorMap.get(row.riskRank).color,
							}"
						>
							{{ rankToColorMap.get(row.riskRank).label }}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="审核建议（仅供参考）" prop="riskDesc" />
			</el-table>
		</card>
		<card class="card">
			<AdminTitle title="重点信息标注" size="20px"></AdminTitle>
			<el-table :data="importantInfoMark">
				<el-table-column label="数据来源" prop="name" />
				<el-table-column label="风险项" prop="riskName" />
				<el-table-column label="数据" prop="riskValue">
					<template slot-scope="{ row }">
						<span :class="{ ['danger-cell']: row.name === '百融' }">{{
							row.riskValue
						}}</span>
					</template>
				</el-table-column>
			</el-table>
		</card>
		<card class="card">
			<AdminTitle title="法院风险信息" size="20px"></AdminTitle>
			<el-table :data="courtRiskInfo" :style="{ marginBottom: '30px' }">
				<el-table-column label="个人涉诉总数" prop="count">
					<template slot-scope="{ row }">
						<span class="danger-cell">{{ row.count }}</span>
					</template>
				</el-table-column>
				<el-table-column label="裁判文书" prop="judgeDocumentCount" />
				<el-table-column label="开庭公告" prop="openCourtNoticeCount" />
				<el-table-column label="执行公告" prop="executeNoticeCount" />
				<el-table-column label="失信公告" prop="dishonestyNoticeCount" />
				<el-table-column label="案件流程" prop="caseProduceCount" />
				<el-table-column label="曝光台" prop="exposureCount" />
			</el-table>
			<el-table :data="personInvolvement">
				<el-table-column label="序号" prop="index" width="100" />
				<el-table-column label="日期" prop="caseTime" width="150" />
				<el-table-column label="类型" prop="dataType" width="150" />
				<el-table-column label="标题" prop="title" />
				<el-table-column label="内容摘要" prop="content" />
			</el-table>
		</card>
		<card class="card">
			<AdminTitle title="网贷行为评估" size="20px"></AdminTitle>
			<div class="online-summary">
				<div class="online-summary-item">
					<div class="online-summary-item-header">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3.5 4C3.5 2.89543 4.39543 2 5.5 2H16.4142L20.5 6.08579V20C20.5 21.1046 19.6046 22 18.5 22H5.5C4.39543 22 3.5 21.1046 3.5 20V4ZM15.5858 4L5.5 4V20H18.5V6.91421L15.5858 4ZM16 11.5H8V9.5H16V11.5ZM13 15.5H8V13.5H13V15.5Z"
								fill="#222222"
							/></svg
						>申请记录
					</div>
					<div class="online-summary-item-row">
						<span>申请命中机构数</span
						><span>{{ renderText(xinYanSpray.applyHitOrgNum) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>申请命中消金机构数</span
						><span>{{
							renderText(xinYanSpray.applyHitConsumeLoanOrgNum)
						}}</span>
					</div>
					<div class="online-summary-item-row">
						<span>申请命中网贷类机构数</span
						><span>{{ renderText(xinYanSpray.applyHitNetLoanOrgNum) }}</span>
					</div>
				</div>
				<div class="online-summary-item">
					<div class="online-summary-item-header">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3.5 4C3.5 2.89543 4.39543 2 5.5 2H16.4142L20.5 6.08579V20C20.5 21.1046 19.6046 22 18.5 22H5.5C4.39543 22 3.5 21.1046 3.5 20V4ZM15.5858 4L5.5 4V20H18.5V6.91421L15.5858 4Z"
								fill="#222222"
							/>
							<path
								d="M8 8H9.82699L12 11.8697L14.173 8H16L13.301 12.4244H15.1419V13.2059H12.8166L12.8028 13.2185V14.3025H15.1419V15.084H12.8028V17H11.1972V15.084H8.87197V14.3025H11.1972V13.2185L11.1834 13.2059H8.87197V12.4244H10.699L8 8Z"
								fill="black"
							/></svg
						>放款记录
					</div>
					<div class="online-summary-item-row">
						<span>网络贷款类机构数</span
						><span>{{ renderText(xinYanSpray.netLoanOrgNum) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>消金贷款类机构数</span
						><span>{{ renderText(xinYanSpray.consumeLoanOrgNum) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>最后一次贷款放款时间</span
						><span>{{ renderText(xinYanSpray.lastLoanDistributeTime) }}</span>
					</div>
				</div>
				<div class="online-summary-item">
					<div class="online-summary-item-header">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3.5 4C3.5 2.89543 4.39543 2 5.5 2H16.4142L20.5 6.08579V20C20.5 21.1046 19.6046 22 18.5 22H5.5C4.39543 22 3.5 21.1046 3.5 20V4ZM15.5858 4L5.5 4V20H18.5V6.91421L15.5858 4ZM14.5 8.5H6.5V6.5H14.5V8.5ZM11.5 12.5H6.5V10.5H11.5V12.5Z"
								fill="#222222"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M13 16.4737L16.6667 13L18 14.2632L13 19L10 16.1579L11.3333 14.8947L13 16.4737Z"
								fill="#222222"
							/></svg
						>履约记录
					</div>
					<div class="online-summary-item-row danger-row">
						<span>正常还款订单数占贷款总订单数比例</span
						><span>{{ renderText(xinYanSpray.normalRepayOrderRate) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>贷款已结清订单数</span
						><span>{{ renderText(xinYanSpray.settledOrderNum) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>最近一次履约距今天数</span
						><span>{{
							renderText(xinYanSpray.lastPerformanceRecentlyDay)
						}}</span>
					</div>
				</div>
				<div class="online-summary-item">
					<div class="online-summary-item-header">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3.5 4C3.5 2.89543 4.39543 2 5.5 2H16.4142L20.5 6.08579V20C20.5 21.1046 19.6046 22 18.5 22H5.5C4.39543 22 3.5 21.1046 3.5 20V4ZM15.5858 4L5.5 4V20H18.5V6.91421L15.5858 4ZM14.5 8.5H6.5V6.5H14.5V8.5ZM11.5 12.5H6.5V10.5H11.5V12.5Z"
								fill="#222222"
							/>
							<path
								d="M12.4142 17.6569L16.6569 13.4142L18.0711 14.8285L13.8284 19.0711L12.4142 17.6569Z"
								fill="#222222"
							/>
							<path
								d="M16.6569 19.0711L12.4142 14.8285L13.8284 13.4142L18.0711 17.6569L16.6569 19.0711Z"
								fill="#222222"
							/></svg
						>逾期记录
					</div>
					<div class="online-summary-item-row danger-row">
						<span>当前逾期机构数</span
						><span>{{ renderText(xinYanProbe.currOverdueOrgNum) }}</span>
					</div>
					<div class="online-summary-item-row danger-row">
						<span>近12月逾期次数</span
						><span>{{ renderText(youFen.m12OverdueNum) }}</span>
					</div>
					<div class="online-summary-item-row danger-row">
						<span>近24月逾期次数</span
						><span>{{ renderText(youFen.m24OverdueNum) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>最大逾期金额</span
						><span>{{ renderText(xinYanProbe.maxOverdueAmount) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>逾期最大金额距今最近天数</span
						><span>{{ renderText(youFen.overdueMaxMoneyRecentlyDay) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>最近逾期距今天数</span
						><span>{{ renderText(youFen.overdueRecentlyDay) }}</span>
					</div>
					<div class="online-summary-item-row">
						<span>最早逾期距今天数</span
						><span>{{ renderText(youFen.overdueHistoryDay) }}</span>
					</div>
				</div>
			</div>
			<el-table :data="overdueRecords" :style="{ marginBottom: '20px' }">
				<el-table-column prop="title">
					<p slot="header" class="table-svg-header">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.91675 3.33317C2.91675 2.4127 3.66294 1.6665 4.58341 1.6665H13.6786L17.0834 5.07133V16.6665C17.0834 17.587 16.3372 18.3332 15.4167 18.3332H4.58341C3.66294 18.3332 2.91675 17.587 2.91675 16.6665V3.33317ZM12.9882 3.33317L4.58341 3.33317V16.6665H15.4167V5.76168L12.9882 3.33317ZM12.0834 7.08317H5.41675V5.4165H12.0834V7.08317ZM9.58342 10.4165H5.41675V8.74984H9.58342V10.4165Z"
								fill="#222222"
							/>
							<path
								d="M10.3453 14.7142L13.8808 11.1786L15.0593 12.3571L11.5238 15.8927L10.3453 14.7142Z"
								fill="#222222"
							/>
							<path
								d="M13.8808 15.8927L10.3453 12.3571L11.5238 11.1786L15.0593 14.7142L13.8808 15.8927Z"
								fill="#222222"
							/>
						</svg>
						逾期记录
					</p>
				</el-table-column>
				<el-table-column label="近1个月" prop="m1"></el-table-column>
				<el-table-column label="近3个月" prop="m3"></el-table-column>
				<el-table-column label="近6个月" prop="m6"></el-table-column>
				<el-table-column label="近12个月" prop="m12"></el-table-column>
				<el-table-column label="近24个月" prop="m24"></el-table-column>
			</el-table>
			<el-table
				:data="performanceRecords"
				:style="{ marginBottom: '20px' }"
				class="performance-records-table"
			>
				<el-table-column prop="title">
					<p slot="header" class="table-svg-header">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.91675 3.33342C2.91675 2.41294 3.66294 1.66675 4.58341 1.66675H13.6786L17.0834 5.07157V16.6667C17.0834 17.5872 16.3372 18.3334 15.4167 18.3334H4.58341C3.66294 18.3334 2.91675 17.5872 2.91675 16.6667V3.33342ZM12.9882 3.33341L4.58341 3.33342V16.6667H15.4167V5.76193L12.9882 3.33341ZM12.0834 7.08341H5.41675V5.41675H12.0834V7.08341ZM9.58342 10.4167H5.41675V8.75008H9.58342V10.4167Z"
								fill="#222222"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M10.8335 13.728L13.8891 10.8333L15.0002 11.8859L10.8335 15.8333L8.3335 13.4648L9.44461 12.4122L10.8335 13.728Z"
								fill="#222222"
							/>
						</svg>
						履约记录
					</p>
				</el-table-column>
				<el-table-column label="近1个月" prop="m1"></el-table-column>
				<el-table-column label="近3个月" prop="m3"></el-table-column>
				<el-table-column label="近6个月" prop="m6"></el-table-column>
				<el-table-column label="近12个月" prop="m12"></el-table-column>
				<el-table-column label="近24个月" prop="m24"></el-table-column>
			</el-table>
			<el-table :data="applicationRecord" :style="{ marginBottom: '20px' }">
				<el-table-column prop="title">
					<p slot="header" class="table-svg-header">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.91675 3.33317C2.91675 2.4127 3.66294 1.6665 4.58341 1.6665H13.6786L17.0834 5.07133V16.6665C17.0834 17.587 16.3372 18.3332 15.4167 18.3332H4.58341C3.66294 18.3332 2.91675 17.587 2.91675 16.6665V3.33317ZM12.9882 3.33317L4.58341 3.33317V16.6665H15.4167V5.76168L12.9882 3.33317Z"
								fill="#222222"
							/>
							<path
								d="M6.66675 6.6665H8.18924L10.0001 9.89129L11.8109 6.6665H13.3334L11.0843 10.3535H12.6183V11.0047H10.6806L10.6691 11.0152V11.9186H12.6183V12.5699H10.6691V14.1665H9.33111V12.5699H7.39339V11.9186H9.33111V11.0152L9.31957 11.0047H7.39339V10.3535H8.91588L6.66675 6.6665Z"
								fill="black"
							/>
						</svg>
						申请记录
					</p>
				</el-table-column>
				<el-table-column label="近7天" prop="d7"></el-table-column>
				<el-table-column label="近15天" prop="d15"></el-table-column>
				<el-table-column label="近1个月" prop="m1"></el-table-column>
				<el-table-column label="近3个月" prop="m3"></el-table-column>
				<el-table-column label="近6个月" prop="m6"></el-table-column>
			</el-table>
			<el-table :data="loanInstitutionData" :style="{ marginBottom: '20px' }">
				<el-table-column prop="title">
					<p slot="header" class="table-svg-header">
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.91675 3.33342C2.91675 2.41294 3.66294 1.66675 4.58341 1.66675H13.6786L17.0834 5.07157V16.6667C17.0834 17.5872 16.3372 18.3334 15.4167 18.3334H4.58341C3.66294 18.3334 2.91675 17.5872 2.91675 16.6667V3.33342ZM12.9882 3.33341L4.58341 3.33342V16.6667H15.4167V5.76193L12.9882 3.33341ZM13.3334 9.58342H6.66675V7.91675H13.3334V9.58342ZM10.8334 12.9167H6.66675V11.2501H10.8334V12.9167Z"
								fill="#222222"
							/>
						</svg>

						贷款机构数据
					</p>
				</el-table-column>
				<el-table-column label="近1个月" prop="m1"></el-table-column>
				<el-table-column label="近3个月" prop="m3"></el-table-column>
				<el-table-column label="近6个月" prop="m6"></el-table-column>
				<el-table-column label="近12个月" prop="m12"></el-table-column>
				<el-table-column label="近24个月" prop="m24"></el-table-column>
			</el-table>
			<el-table :data="loanData">
				<el-table-column label="放款数据" prop="title"></el-table-column>
				<el-table-column label="近1个月" prop="m1"></el-table-column>
				<el-table-column label="近3个月" prop="m3"></el-table-column>
				<el-table-column label="近6个月" prop="m6"></el-table-column>
				<el-table-column label="近12个月" prop="m12"></el-table-column>
				<el-table-column label="近24个月" prop="m24"></el-table-column>
			</el-table>
		</card>
	</div>
</template>

<script>
import { getOrderRiskReviewDetailAPI } from "./api";

export default {
	name: "JiShen",

	data() {
		return {
			detail: {},
			alipay: {},
			baiRong: {},
			youFen: {},
			xinYanSpray: {},
			xinYanProbe: {},
			xinYanDocument: {},
			rankToColorMap: new Map([
				["RANK1", { color: "#28D576", label: "极低风险" }],
				["RANK2", { color: "#28D5D5", label: "低风险" }],
				["RANK3", { color: "#1483EA", label: "中风险" }],
				["RANK4", { color: "#FFAC46", label: "高风险" }],
				["RANK5", { color: "#D05252", label: "极高风险" }],
			]),
      enableHitScore: false,
		};
	},
	computed: {
		member() {
			return this.detail.member || {};
		},
		finalLevel() {
			return this.detail.orderScoreProfile
				? this.detail.orderScoreProfile.finalHitGrade
				: "";
		},
		scoreProfileData() {
			return this.detail.scoreProfileData || {};
		},
		riskListTable() {
			return this.alipay.riskList || [];
		},
		importantInfoMark() {
			return [
				{
					name: "优分",
					riskName: "运营商在网时长",
					riskValue: this.renderText(this.youFen.netTime),
					reviewSuggestions: "TODO",
				},
				{
					name: "优分",
					riskName: "自然人可信度识别",
					riskValue: this.renderText(this.youFen.identityCredence),
					reviewSuggestions: "TODO",
				},
				{
					name: "优分",
					riskName: "金融黑名单等级",
					riskValue: this.renderText(this.youFen.blacklistLevel),
					reviewSuggestions: "TODO",
				},
				{
					name: "百融",
					riskName: "反欺诈评分",
					riskValue: this.renderText(this.baiRong.afRuleScore),
					reviewSuggestions: "TODO",
				},
				{
					name: "百融",
					riskName: "债偿压力指数",
					riskValue: this.renderText(this.baiRong.debtRepayStress),
					reviewSuggestions: "TODO",
				},
			];
		},
		courtRiskInfo() {
			if (!this.youFen.personInvolvement) {
				return [];
			}

			return [
				{
					count: this.renderText(this.youFen.personInvolvement.count),
					judgeDocumentCount: this.renderText(this.youFen.personInvolvement.judgeDocumentCount),
					openCourtNoticeCount: this.renderText(
						this.youFen.personInvolvement.openCourtNoticeCount
					),
					executeNoticeCount: this.renderText(this.youFen.personInvolvement.executeNoticeCount),
					dishonestyNoticeCount: this.renderText(
						this.youFen.personInvolvement.dishonestyNoticeCount
					),
					caseProduceCount: this.renderText(this.youFen.personInvolvement.caseProduceCount),
					exposureCount: this.renderText(this.youFen.personInvolvement.exposureCount),
				},
			];
		},
		personInvolvement() {
			if (!this.youFen.personInvolvement || !this.youFen.personInvolvement.list) {
				return [];
			}

			return this.youFen.personInvolvement.list.map((row, index) => {
				return {
					index: index + 1,
					caseTime: this.renderText(row.caseTime),
					dataType: this.renderText(row.dataType),
					title: this.renderText(row.title),
					content: this.renderText(row.content),
				};
			});
		},
		systemScoreDetail() {
			return (this.detail.hitScoreBillList ?? []).map((record, index) => {
				return {
					index: index + 1,
					ruleName: this.renderText(record.ruleName),
					hitScore: this.renderText(record.hitScore),
					hitSuggestDesc: this.renderText(record.hitSuggestDesc),
				};
			});
		},
		overdueRecords() {
			return [
				{
					title: "MO+逾期贷款笔数",
					m1: this.renderText(this.xinYanSpray.m1M0OverdueLoanNum),
					m3: this.renderText(this.xinYanSpray.m3M0OverdueLoanNum),
					m6: this.renderText(this.xinYanSpray.m6M0OverdueLoanNum),
					m12: this.renderText(this.xinYanSpray.m12M0OverdueLoanNum),
					m24: this.renderText(this.xinYanSpray.m24M0OverdueLoanNum),
				},
				{
					title: "累计逾期金额",
					m1: this.renderText(this.xinYanSpray.m1TotalOverdueAmount),
					m3: this.renderText(this.xinYanSpray.m3TotalOverdueAmount),
					m6: this.renderText(this.xinYanSpray.m6TotalOverdueAmount),
					m12: this.renderText(this.xinYanSpray.m12TotalOverdueAmount),
					m24: this.renderText(this.xinYanSpray.m24TotalOverdueAmount),
				},
			];
		},
		performanceRecords() {
			return [
				{
					title: "履约贷款次数",
					m1: this.renderText(this.xinYanSpray.m1PerformanceLoanNum),
					m3: this.renderText(this.xinYanSpray.m3PerformanceLoanNum),
					m6: this.renderText(this.xinYanSpray.m6PerformanceLoanNum),
					m12: this.renderText(this.xinYanSpray.m12PerformanceLoanNum),
					m24: this.renderText(this.xinYanSpray.m24PerformanceLoanNum),
				},
				{
					title: "失败扣款笔数",
					m1: this.renderText(this.xinYanSpray.m1FailDeductNum),
					m3: this.renderText(this.xinYanSpray.m3FailDeductNum),
					m6: this.renderText(this.xinYanSpray.m6FailDeductNum),
					m12: this.renderText(this.xinYanSpray.m12FailDeductNum),
					m24: this.renderText(this.xinYanSpray.m24FailDeductNum),
				},
				{
					title: "履约贷款总金额",
					m1: this.renderText(this.xinYanSpray.m1PerformanceLoanAmount),
					m3: this.renderText(this.xinYanSpray.m3PerformanceLoanAmount),
					m6: this.renderText(this.xinYanSpray.m6PerformanceLoanAmount),
					m12: this.renderText(this.xinYanSpray.m12PerformanceLoanAmount),
					m24: this.renderText(this.xinYanSpray.m24PerformanceLoanAmount),
				},
			];
		},
		applicationRecord() {
			return [
				{
					title: "线上消费分期的次数",
					d7: this.renderText(this.baiRong.d7CoonAllNum),
					d15: "-",
					m1: "-",
					m3: "-",
					m6: "-",
				},
				{
					title: "线上消费分期的机构数",
					d7: this.renderText(this.baiRong.d7CoonOrgNum),
					d15: "-",
					m1: "-",
					m3: "-",
					m6: "-",
				},
				{
					title: "持牌小贷机构申请次数",
					d7: this.renderText(this.baiRong.d7NbankSloanAllNum),
					d15: "-",
					m1: "-",
					m3: "-",
					m6: "-",
				},
				{
					title: "持牌小贷机构申请机构数",
					d7: this.renderText(this.baiRong.d7NbankSloanOrgNum),
					d15: "-",
					m1: "-",
					m3: "-",
					m6: "-",
				},
				{
					title: "持牌融资租赁机构申请次数",
					d7: "-",
					d15: this.renderText(this.baiRong.d15NbankFinleaAllNum),
					m1: "-",
					m3: "-",
					m6: "-",
				},
				{
					title: "非银机构周末申请机构数",
					d7: "-",
					d15: this.renderText(this.baiRong.d15NbankWeekOrgNum),
					m1: "-",
					m3: "-",
					m6: "-",
				},
				{
					title: "非银机构申请次数",
					d7: "-",
					d15: "-",
					m1: this.renderText(this.baiRong.m1IdNbankAllNum),
					m3: this.renderText(this.baiRong.m3IdNbankAllNum),
					m6: this.renderText(this.baiRong.m6NbankAllNum),
				},
				{
					title: "申请汽车金融的机构数",
					d7: "-",
					d15: "-",
					m1: this.renderText(this.baiRong.m1AfOrgNum),
				},
				{
					title: "非银机构-其他申请次数",
					d7: "-",
					d15: "-",
					m1: "-",
					m3: "-",
					m6: this.renderText(this.baiRong.m6NbankOthAllNum),
				},
				{
					title: "非银机构-消费类分期机构申请次数",
					d7: "-",
					d15: "-",
					m1: "-",
					m3: "-",
					m6: this.renderText(this.baiRong.m6NbankCfAllNum),
				},
				{
					title: "按身份证号查询在非银机构申请次数",
					d7: "-",
					d15: "-",
					m1: "-",
					m3: "-",
					m6: this.renderText(this.baiRong.m6IdNbankAllNum),
				},
			];
		},
		loanInstitutionData() {
			return [
				{
					title: "贷款机构数",
					m1: this.renderText(this.xinYanSpray.m1LoanOrgNum),
					m3: this.renderText(this.xinYanSpray.m3LoanOrgNum),
					m6: this.renderText(this.xinYanSpray.m6LoanOrgNum),
					m12: this.renderText(this.xinYanSpray.m12LoanOrgNum),
					m24: this.renderText(this.xinYanSpray.m24LoanOrgNum),
				},
				{
					title: "消金类贷款机构数",
					m1: this.renderText(this.xinYanSpray.m1ConsumeLoanOrgNum),
					m3: this.renderText(this.xinYanSpray.m3ConsumeLoanOrgNum),
					m6: this.renderText(this.xinYanSpray.m6ConsumeLoanOrgNum),
					m12: this.renderText(this.xinYanSpray.m12ConsumeLoanOrgNum),
					m24: this.renderText(this.xinYanSpray.m24ConsumeLoanOrgNum),
				},
				{
					title: "网贷类贷款机构数",
					m1: this.renderText(this.xinYanSpray.m1NetLoanOrgNum),
					m3: this.renderText(this.xinYanSpray.m3NetLoanOrgNum),
					m6: this.renderText(this.xinYanSpray.m6NetLoanOrgNum),
					m12: this.renderText(this.xinYanSpray.m12NetLoanOrgNum),
					m24: this.renderText(this.xinYanSpray.m24NetLoanOrgNum),
				},
			];
		},
		loanData() {
			return [
				{
					title: "贷款笔数",
					m1: this.renderText(this.xinYanSpray.m1LoanNum),
					m3: this.renderText(this.xinYanSpray.m3LoanNum),
					m6: this.renderText(this.xinYanSpray.m6LoanNum),
					m12: this.renderText(this.xinYanSpray.m12LoanNum),
					m24: this.renderText(this.xinYanSpray.m24LoanNum),
				},
				{
					title: "贷款总金额",
					m1: this.renderText(this.xinYanSpray.m1LoanAmount),
					m3: this.renderText(this.xinYanSpray.m3LoanAmount),
					m6: this.renderText(this.xinYanSpray.m6LoanAmount),
					m12: this.renderText(this.xinYanSpray.m12LoanAmount),
					m24: this.renderText(this.xinYanSpray.m24LoanAmount),
				},
			];
		},
	},
	mounted() {
    this.enableHitScore = this.$route.query.orderType == 2 || this.$route.query.orderType == 4;
		this.getRiskReview();
	},
	methods: {
		renderText(value) {
			return value ?? "-";
		},
		async getRiskReview() {
			const res = await getOrderRiskReviewDetailAPI(this.$route.params.id);
			this.detail = res;
			this.alipay = res.alipay;
			this.baiRong = res.baiRong;
			this.youFen = res.youFen;
			this.xinYanSpray = res.xinYanSpray;
			this.xinYanProbe = res.xinYanProbe;
			this.xinYanDocument = res.xinYanDocument;
		},
	},
};
</script>

<style lang="scss" scoped>
.card {
	border-radius: 16px;
	margin-bottom: 20px;
}

.danger-cell {
	color: red;
}
.without-layout .wrapper-jishen /deep/ {
	padding: 0 14% !important;
	flex: 1;
	overflow-y: scroll;
	margin-top: 15px;
	.table-svg-header {
		display: flex;
		gap: 5px;
		align-items: center;
	}
	.el-table__header .el-table__cell {
		font-weight: 600;
	}
	.performance-records-table tbody tr:not(:last-child) td {
		&:first-child {
			background: #faeeee;
		}
		color: #d05252;
	}
}
.rank-tag {
	display: flex;
	height: 25px;
	width: 88px;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 25px;
}

.online-summary {
	display: flex;
	flex-wrap: wrap;
	row-gap: 16px;
	column-gap: 16px;
	margin-bottom: 12px;

	.online-summary-item {
		width: calc(50% - 8px);
		background-color: #f7f8fa;
		border-radius: 8px;
		padding: 16px;

		.online-summary-item-header {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 12px;
			color: #222222;
			display: flex;
			align-items: center;
			gap: 5px;
		}

		.online-summary-item-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 22px;

			&:not(:last-child) {
				margin-bottom: 12px;
			}

			& > span:first-child {
				color: #666666;
			}

			& > span:last-child {
				color: #222222;
			}

			&.danger-row span {
				color: #d05252 !important;
			}
		}
	}
}

.base-info-new {
	border-color: transparent;
	margin-top: 20px;
	width: 100%;
	border-radius: 16px;
	color: #222222;
	.base-info-top {
		display: flex;
		align-items: flex-start;
		.id-card-pic {
			margin-right: 3%;
		}
		.person-info {
			position: relative;
			flex: 1;
			display: flex;
			flex-direction: column;
			line-height: 35px;

			.mr50 {
				margin-right: 50px;
			}
			.el-tag {
				height: 25px;
				border-radius: 15px;
				margin-right: 10px;
				line-height: 25px;
			}
			.history-tag {
				.el-tag {
					height: 28px;
					line-height: 28px;
					margin-top: 20px;
				}
			}
			.el-tag.el-tag--success {
				background-color: #fff;
				color: #666666;
				border-color: #e4e4e4;
			}
			.name {
				color: #222222;
				font-size: 18px;
				font-family: 微软雅黑;
				margin-right: 2%;
			}
			.rating {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				font-size: 16px;
				.edit-btn {
					font-size: 12px;
				}
			}
		}
	}
}
.tag-ku {
	padding: 4px 12px;
	border-radius: 46px;
	font-size: 12px;
	margin-right: 5px;
	color: #fff;
	.el-icon-warning {
		color: #fff;
		font-size: 14px;
		margin-right: 5px;
	}
}
// 黑名单
.t1 {
	background-color: #d05252;
}
.t2 {
	background-color: #fbb438;
}
// 白名单
.t3 {
	background: #4cd2a2;
}
</style>
