<template>
	<card class="store-info">
		<AdminTitle title="门店信息" size="20px"> </AdminTitle>
		<div class="store-info-detail">
			<el-image
				style="
					width: 100px;
					border-radius: 8px;
					height: 100px;
					margin-right: 20px;
				"
				:src="detailInfo.store ? detailInfo.store.coverImageUrl : ''"
				:preview-src-list="[
					detailInfo.store ? detailInfo.store.coverImageUrl : '',
				]"
			>
				<div
					style="
						background: #f5f7fa;
						width: 100px;
						height: 100px;
						line-height: 100px;
						text-align: center;
						color: #c0c4cc;
					"
					slot="error"
				>
					暂无图片
				</div>
			</el-image>
			<div class="store-info-detail-right">
				<div class="store-title fw700">
					{{ detailInfo.store ? detailInfo.store.storeName : "" }}
				</div>
				<div
					class="store-tag"
					v-if="
						detailInfo.store &&
						detailInfo.store.storeTagList &&
						detailInfo.store.storeTagList.length > 0
					"
				>
					<el-tag
						type="danger"
						v-for="(item, i) in detailInfo.store.storeTagList"
						:key="i"
						>{{ item }}</el-tag
					>
				</div>
				<div class="store-info">
					<span class="label">BD</span>
					<span class="name">{{
						detailInfo.store ? detailInfo.store.businessUserName : ""
					}}</span>
					<span class="label">入驻时间</span>
					<span>{{ detailInfo.store ? detailInfo.store.createDate : "" }}</span>
				</div>
			</div>
		</div>
		<div class="cost-details">
			<div class="cost-title">门店数据</div>
			<div class="label">门店结算金额</div>
		</div>
		<el-row :gutter="20">
			<el-col :span="8">
				<card class="store-info special-card">
					<p>
						<span class="label">申请订单量</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.applyOrderNum : ""
						}}</span>
					</p>
					<p>
						<span class="label">成交订单量</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.dealOrderNum : ""
						}}</span>
					</p>
					<p>
						<span class="label">在租订单量</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.rentOrderNum : ""
						}}</span>
					</p>
					<p>
						<span class="label">在逾订单量</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.overdueOrderNum : ""
						}}</span>
					</p>
					<p>
						<span class="label">已结清订单量</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.completeOrderNum : ""
						}}</span>
					</p>
				</card>
			</el-col>
			<el-col :span="8">
				<card class="store-info special-card">
					<el-tag type="warning">重点关注</el-tag>
					<p>
						<span class="label">设备离线率</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.offlineRate : ""
						}}</span>
					</p>
					<p>
						<span class="label">订单逾期率</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.overdueOrderRate : ""
						}}</span>
					</p>
					<p>
						<span class="label">订单取消率</span>
						<span class="value">{{
							detailInfo.store ? detailInfo.store.cancelOrderRate : ""
						}}</span>
					</p>
				</card>
			</el-col>
			<el-col :span="8">
				<card class="store-info special-card enter">
					<img src="@/assets/img/core.png" />
					<div class="number">
						<div class="value" v-if="detailInfo.storeSettleAmount == ''">
							--
						</div>
						<div class="value" v-else>
							{{ detailInfo.storeSettleAmount }}<span class="unit">元</span>
						</div>
<!--						<el-button-->
<!--							type="primary"-->
<!--							size="small"-->
<!--							@click="startCalc"-->
<!--							:disabled="-->
<!--								!detailInfo.store ||-->
<!--								!isShowBtn(AUTH_BTN.order_store_calc)-->
<!--							"-->
<!--							>{{ btnName }}</el-button-->
<!--						>-->
					</div>
				</card>
			</el-col>
		</el-row>
		<!-- 门店金额结算 -->
		<el-dialog
			class="store-dialog"
			title="门店待结算金额"
			:visible.sync="show_store_calc"
			width="800px"
		>
			<el-form
				class="store-box"
				ref="storeForm"
				:model="storeData"
				label-width="140px"
			>
				<el-form-item label="订单号：">
					{{ this.$route.params.id }}
				</el-form-item>
				<el-form-item label="商品信息：">
					{{ calc_result.goodsTitle }}
				</el-form-item>
				<el-form-item label="商品进货价：">
					￥{{ calc_result.costAmount }}
				</el-form-item>
				<el-form-item label="客户首付款：">
					<div class="row-box">
						<span> ￥{{ calc_result.prepayAmount }}</span>
						<el-select
							v-if="btnName == '录入'"
							v-model="storeData.prepayPayeeType"
							size="mini"
							placeholder="请选择"
							@change="refreshCalcResult"
						>
							<el-option label="平台收取" :value="1"> </el-option>
							<el-option label="门店收取" :value="2"> </el-option>
						</el-select>
						<span v-else
							>({{
								["平台收取", "门店收取"][storeData.prepayPayeeType - 1]
							}})</span
						>
					</div>
				</el-form-item>
				<el-form-item label="监管锁费用：">
					<div class="row-box">
						<span> ￥{{ calc_result.lockAmount }}</span>
						<el-select
							v-if="btnName == '录入'"
							@change="refreshCalcResult"
							v-model="storeData.lockPayeeType"
							size="mini"
							placeholder="请选择"
						>
							<el-option label="平台收取" :value="1"> </el-option>
							<el-option label="门店收取" :value="2"> </el-option>
						</el-select>
						<span v-else
							>({{
								["平台收取", "门店收取"][storeData.lockPayeeType - 1]
							}})</span
						>
					</div>
				</el-form-item>
				<el-form-item label="订单押金：">
					<div class="row-box">
						<el-input-number
							v-if="btnName == '录入'"
							v-model="storeData.depositAmount"
							size="mini"
							:precision="2"
							:min="0"
							@change="refreshCalcResult"
						></el-input-number>
						<span v-else>{{ storeData.depositAmount }}</span>
						<el-select
							v-if="btnName == '录入'"
							v-model="storeData.depositPayeeType"
							size="mini"
							placeholder="请选择"
							@change="refreshCalcResult"
						>
							<el-option label="平台收取" :value="1"> </el-option>
							<el-option label="门店收取" :value="2"> </el-option>
						</el-select>
						<span v-else
							>({{
								["平台收取", "门店收取"][storeData.depositPayeeType - 1]
							}})</span
						>
					</div>
				</el-form-item>
				<el-form-item label="门店待结算金额：">
					<span class="calc-money"> ￥ {{ calc_result.settleAmount }} </span>
				</el-form-item>
				<el-form-item label="门店收款信息：">
					<div v-if="calc_result.settleType == 2">
						<p>
							姓名：{{ calc_result.store ? calc_result.store.payeeIdName : "" }}
						</p>
						<p>
							支付宝账号：{{
								calc_result.store ? calc_result.store.payeeAlipayAccount : ""
							}}
						</p>
					</div>
					<div v-else>线下打款</div>
				</el-form-item>
				<el-form-item label="" label-width="0" v-if="btnName == '录入'">
					<div style="text-align: center; padding-top: 20px">
						<el-button type="primary" size="medium" @click="submitCalc"
							>保 存</el-button
						>
					</div>
				</el-form-item>
			</el-form>
		</el-dialog>
	</card>
</template>

<script>
import {
	settlementAmountDetails,
	calcStoreSettleAPI,
	calcStoreSaveApi,
} from "./api";
import { authBtnMixin } from "@/mixins/authBtnMixin";

export default {
	components: {},
	name: "storeInformation",
	props: {
		detailInfo: {
			type: Object,
			default: () => {},
		},
		flag: {
			type: Boolean,
			default: true,
		},
	},
	mixins: [authBtnMixin],
	inject: ["reload"],
	data() {
		return {
			list1: [
				{
					label: "历史提交订单量",
					value: 10,
				},
				{
					label: "历史成交订单量",
					value: 10,
				},
				{
					label: "在租订单量",
					value: 10,
				},
				{
					label: "在逾订单量",
					value: 10,
				},
				{
					label: "已结清订单量",
					value: 10,
				},
			],
			list2: [
				{
					label: "设备离线率",
					value: 10,
				},
				{
					label: "订单逾期率",
					value: 10,
				},
				{
					label: "订单取消率",
					value: 10,
				},
			],
			show_store_calc: false,
			calc_result: {
				orderNo: "",
				goodsTitle: "",
				costAmount: "",
				prepayAmount: "",
				prepayPayeeType: 1,
				lockAmount: "",
				lockPayeeType: 1,
				depositAmount: "",
				depositPayeeType: 1,
				settleAmount: "",
				settleType: "",
			},
			storeData: {
				orderNo: this.$route.params.id,
				prepayPayeeType: 1,
				lockPayeeType: 1,
				depositAmount: "",
				depositPayeeType: 1,
			},
		};
	},
	computed: {
		btnName() {
			return ["审核中", "待支付", "待签章", "待发货"].includes(
				this.detailInfo.statusName
			)
				? "录入"
				: "详情";
		},
	},
	watch: {},
	mounted() {},

	methods: {
		async refreshCalcResult() {
			let res = await calcStoreSettleAPI({
				orderNo: this.$route.params.id,
				prepayPayeeType: this.storeData.prepayPayeeType,
				lockPayeeType: this.storeData.lockPayeeType,
				depositAmount: this.storeData.depositAmount,
				depositPayeeType: this.storeData.depositPayeeType,
			});
			this.calc_result = { ...this.calc_result, ...res };
		},
		// 计算门店结算金额
		async submitCalc() {
			await calcStoreSaveApi(this.storeData);
			this.show_store_calc = false;
			this.$emit("updateDetail", true);
		},
		// 开始计算
		async startCalc() {
			this.calc_result = await settlementAmountDetails({
				orderNo: this.$route.params.id,
			});
			this.show_store_calc = true;
			this.storeData = { ...this.storeData, ...this.calc_result };
		},
	},
};
</script>

<style lang="scss" scoped>
.store-info /deep/ {
	border-color: transparent;
	margin-top: 10px;
	width: 100%;
	border-radius: 16px;
	color: #222222;
	.el-select {
		margin-left: 10px;
	}
	.label {
		color: #666666;
		font-size: 14px;
	}
	.ivu-card {
		border-radius: 18px;
	}
	.store-info-detail {
		display: flex;
		.store-info-detail-right {
			display: flex;
			flex-direction: column;
			.store-title {
				color: #333333;
				font-size: 16px;
			}
			.store-tag {
				margin-top: 8px;
			}
			.store-info {
				margin-top: 15px;
			}
      .label {
        margin-top: 8px;
        margin-right: 15px;
        font-size: 12px;
      }
      .name {
        margin: 0 30px 0 0;
      }
		}

		.el-tag.el-tag--success {
			background-color: #fff;
			color: #666666;
			border-color: #e4e4e4;
		}
	}
	.el-tag {
		height: 25px;
		border-radius: 15px;
		margin-right: 10px;
		line-height: 25px;
	}
	.el-tag.el-tag--info {
		background-color: #fff;
		color: #1483ea;
		border-color: #1483ea;
	}
	.el-tag.el-tag--warning {
		background-color: #fff;
		color: #f15f5f;
		border-color: #f15f5f;
	}
	.cost-details {
		display: flex;
		justify-content: space-between;
		margin-top: 15px;

		.cost-title {
			color: #666666;
			font-size: 14px;
		}
		.label {
			width: 32%;
			text-align: left;
		}
	}
	.el-dialog__footer {
		padding-bottom: 30px !important;
	}
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title-style {
			color: #333333;
			flex-wrap: 600;
			font-size: 16px;
		}
		.supple-inform-edit {
			cursor: pointer;
			background: #f7f8fa;
			color: #333333;
			padding: 8px 18px;
			border-radius: 45px;
		}
	}

	.update-final-grade-dialog {
		.el-dialog__footer {
			padding-top: 0;
		}
	}

  .store-dialog {
    .el-dialog__body {
      padding: 10px 20px 20px 10px;
    }
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
.special-card /deep/ {
	background: #f7f8fa;
	color: #222222;
	position: relative;
	height: 182px;
	.ivu-card-body {
		height: 100%;
		display: flex;
		justify-content: space-around;
		flex-direction: column;
		align-items: center;
	}

	.value {
		margin-left: 15px;
		min-width: 80px;
		display: inline-block;
    font-weight: 700;
	}
	.label {
		text-align: right;
		min-width: 110px;
		display: inline-block;
	}
	.el-icon-edit {
		margin-left: 30px;
		cursor: pointer;
		color: #4badf8;
	}
}
.enter {
	img {
		position: absolute;
		left: 10px;
		bottom: 0;
	}
	.number {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 100%;
		margin-top: -18%;
		.value {
			font-size: 28px;
			color: #1d2129;
			display: flex;
			align-items: center;
			.unit {
				margin-left: 10px;
				font-size: 12px;
			}
		}

		.el-button {
			border-radius: 20px;
		}
	}
}
.fei-box {
	.fei-item {
		margin-bottom: 10px;
		.title {
			font-weight: 700;
			font-size: 16px;
			margin-bottom: 10px;
		}
	}
}
.calc-money {
	font-size: 22px;
	font-weight: 600;
	color: #f56c6c;
}
.fw700 {
  font-weight: 700;
}
</style>
