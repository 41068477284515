<template>
    <card class="base-info-new">
        <div class="base-info-top">
            <div class="id-card-pic">
                <el-image
                    style="width: 100px; border-radius: 8px; height: 100px; margin-right: 10px"
                    :src="member.identityFrontImageUrl"
                    :preview-src-list="[member.identityFrontImageUrl]"
                >
                    <div
                        style="
                            background: #f5f7fa;
                            width: 100px;
                            height: 100px;
                            line-height: 100px;
                            text-align: center;
                            color: #c0c4cc;
                        "
                        slot="error"
                    >
                        暂无图片
                    </div>
                </el-image>
                <el-image
                    style="width: 100px; border-radius: 8px; height: 100px"
                    :src="member.identityBackImageUrl"
                    :preview-src-list="[member.identityBackImageUrl]"
                >
                    <div
                        style="
                            background: #f5f7fa;
                            width: 100px;
                            height: 100px;
                            line-height: 100px;
                            text-align: center;
                            color: #c0c4cc;
                        "
                        slot="error"
                    >
                        暂无图片
                    </div>
                </el-image>
            </div>
            <div class="person-info">
                <div>
                    <span class="name fw700">{{ member.memberName }}</span>
                    <el-tag type="success">年龄：{{ member.age }}</el-tag>
                    <el-tag type="success">性别：{{ member.genderName || '' }}</el-tag>
                    <span v-for="(item, i) in detailInfo.rosterLevelList" :key="i">
                        <span class="tag-ku t3" v-if="item == 1" style="margin: 2px">白名单</span>
                        <span class="tag-ku t1" v-if="item == 2" style="margin: 2px"
                            ><i class="el-icon-warning"></i>黑名单</span
                        >
                        <span class="tag-ku t2" v-if="item == 3" style="margin: 2px"
                            ><i class="el-icon-warning"></i>灰名单</span
                        >
                    </span>
                </div>
                <div>
                    <span class="mr50">
                        <span class="label">手机号：</span>
                        <span class="fw700 mr20">{{ member.mobile }}</span>
                        <el-button type="text" @click="copy">复制</el-button>
                    </span>
                    <span>
                        <span class="label">身份证号：</span>
                        <span class="fw700">{{ member.identityNoPlain }}</span>
                    </span>
                </div>
                <div>
                    <span>
                        <span class="label">户籍所在地：</span>
                        <span class="fw700">{{ member.identityAddress || '' }}</span>
                    </span>
                </div>
                <div class="history-tag">
                    <el-tag type="success"
                        >历史支付单数：{{ member ? member.dealOrderNum : '' }}</el-tag
                    >
                    <!-- <el-tag type="success" v-if="member && member.dealOrderNum > 0"
						>最长逾期天数：{{ member ? member.overdueDayNum : "" }}</el-tag
					> -->
                    <el-tag type="success" v-if="member && member.dealOrderNum > 0"
                        >最长延期天数：{{ member ? member.extendDayNum : '' }}</el-tag
                    >
                </div>
                <div class="rating" v-if="!!detailInfo.store">
                    <TagLvNew
                        :name="
                            scoreProfileData.finalHitGrade ? scoreProfileData.finalHitGrade : 'ZW'
                        "
                    >
                    </TagLvNew>
                    <p>
                        <span>最终评级</span>
                        <el-button
                            v-if="scoreProfileData.sysHitScore >= 0"
                            class="edit-btn"
                            style="margin-left: 5px"
                            type="text"
                            size="small"
                            icon="el-icon-edit"
                            :disabled="!isShowBtn(AUTH_BTN.review_list_detail_update_final_grade)"
                            @click="updateFinalGrade"
                            >修改</el-button
                        >
                    </p>
                </div>
            </div>
        </div>
        <div class="title">
            <span class="title-style fw700">客户补充资料</span>
            <span class="supple-inform-edit" @click="openSuppleInform">编辑</span>
        </div>
        <card class="base-info-new special-card">
            <el-row class="customer">
                <el-col
                    :span="item.width ? item.width : 8"
                    v-for="(item, index) in personInfoList"
                    :key="index"
                >
                    <span class="label">{{ item.label }}</span>
                    <span class="value">{{
                        item.dec ? scoreProfileData[item.dec] : scoreProfileData[item.prop] || '-'
                    }}</span>
                </el-col>
            </el-row>
        </card>

        <el-dialog
            title="最终评级修改"
            :visible.sync="updateFinalGradeDialog"
            width="500px"
            class="update-final-grade-dialog"
        >
            <el-form ref="updateFinalGradeForm">
              <el-radio-group v-model="finalGradeData.grade">
                <el-radio
                    @click.native.prevent="clickFinalGrade(item)"
                    v-for="item in gradeList"
                    :key="item.value"
                    :label="item.value"
                >
                  {{ item.name }}
                </el-radio>
              </el-radio-group>
              <el-form-item label="备注：" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
                <div class="final-grade-remark">
                  <el-input
                      style="width: 100%"
                      v-model="finalGradeData.remark"
                      :rows="4"
                      type="textarea"
                      placeholder="请输入"
                  ></el-input>
                </div>
              </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button @click="updateFinalGradeDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="saveFinalGrade" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="客户补充资料修改"
            :visible.sync="suppleInformDialog"
            width="800px"
            class="update-final-grade-dialog"
        >
            <el-row>
                <el-form
                    :model="scoreProfileData"
                    ref="scoreProfileData"
                    label-width="130px"
                    class="demo-ruleForm"
                >
                    <el-col
                        :span="item.prop == 'workCompany' ? 16 : 8"
                        v-for="(item, index) in personInfoList"
                        :key="index"
                    >
                        <el-form-item
                            :label="item.label"
                            :prop="item.prop"
                            :rules="
                                item.require
                                    ? [
                                          {
                                              required: true,
                                              message: '该项不能为空',
                                              trigger: 'blur',
                                          },
                                      ]
                                    : []
                            "
                            v-if="!item.type"
                        >
                            <el-input
                                v-model="scoreProfileData[item.prop]"
                                size="mini"
                                placeholder="请输入"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            :label="item.label"
                            :prop="item.prop"
                            v-else
                            :rules="
                                item.require
                                    ? [
                                          {
                                              required: true,
                                              message: '该项不能为空',
                                              trigger: ['change', 'blur'],
                                          },
                                      ]
                                    : []
                            "
                        >
                            <SelectDict
                                width="100%"
                                size="mini"
                                v-model="scoreProfileData[item.prop]"
                                :dictCode="item.type"
                            ></SelectDict>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <div slot="footer">
                <el-button @click="suppleInformDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="saveProfile" size="small">保 存</el-button>
            </div>
        </el-dialog>
    </card>
</template>

<script>
import SelectDict from '@/views/components/select-dict.vue';
import { updateFinalHitGradeAPI, hitOrderScoreProfileAPI, saveMemberProfileAPI } from './api';
import { authBtnMixin } from '@/mixins/authBtnMixin';
import { getDictEnumListAPI } from '@/enum/dict';
export default {
    mixins: [authBtnMixin],
    components: { SelectDict },
    name: 'BaseInfo',
    props: {
        detailInfo: {
            type: Object,
            default: () => {},
        },
        flag: {
            type: Boolean,
            default: true,
        },
        show_type: {
            type: String,
            default: 'base',
        },
    },
    inject: ['reload'],
    data() {
        return {
            scoreProfileData: {
                orderNo: '',
                memberId: '',
                sysHitScore: '',
                sysHitSuggestDesc: '',
                sysHitGrade: '',
                finalHitGrade: '',
                zmScore: '',
                creditStatus: '',
                rentHistory: '',
                involveStatus: '',
                inSchoolStatus: '',
                workCompany: '',
                jobCategory: '',
                jobName: '',
                income: '',
            },
            personInfoList: [
                {
                    prop: 'zmScore',
                    label: '支付宝芝麻分：',
                    require: true,
                },
                {
                    prop: 'creditStatus',
                    label: '信用情况：',
                    type: 'member:creditStatus',
                    require: true,
                    dec: 'creditStatusDesc',
                },
                {
                    prop: 'rentHistory',
                    label: '租机历史：',
                    type: 'member:rentHistory',
                    dec: 'rentHistoryDesc',
                    require: true,
                },
                {
                    prop: 'inSchoolStatus',
                    label: '是否学生在读：',
                    type: 'member:inSchoolStatus',
                    dec: 'inSchoolStatusDesc',
                    require: true,
                },
                {
                    prop: 'involveStatus',
                    label: '涉诉情况：',
                    type: 'member:involveStatus',
                    width: 16,
                    dec: 'involveStatusDesc',
                    require: true,
                },
                {
                    prop: 'income',
                    label: '薪资：',
                },
                {
                    prop: 'jobCategory',
                    label: '岗位：',
                    type: 'member:jobCategory',
                    dec: 'jobCategoryName',
                },

                {
                    prop: 'workCompany',
                    label: '工作单位：',
                },
            ],
            gradeList: [],
            finalGradeData: {
                grade: '',
                remark: '',
            },
            suppleInformDialog: false,
            updateFinalGradeDialog: false,
        };
    },
    computed: {
        member() {
            let mem = {};
            if (this.detailInfo.member) {
                mem = this.detailInfo.member;
            }
            return mem;
        },
        orderScoreProfile() {
            return this.detailInfo.orderScoreProfile || {};
        },
    },
    watch: {
        orderScoreProfile(val) {
            if (val && this.flag) {
                this.scoreProfileData = { ...this.orderScoreProfile, ...val };
            }
        },
    },
    mounted() {},

    methods: {
        copy() {
            navigator.clipboard.writeText(`${this.member.memberName} ${this.member.mobile}`);

			this.$message({
				message: "复制成功",
				type: "success",
			});
        },
        // 保存客户补充资料
        saveProfile() {
            this.$refs.scoreProfileData.validate((valid) => {
                if (valid) {
                    if (this.detailInfo.store) {
                        hitOrderScoreProfileAPI(this.scoreProfileData).then(() => {
                          this.$message.success('保存成功');
                          this.suppleInformDialog = false;
                          this.$emit('update:flag', true);
                          this.$emit('updateDetail');
                        });
                    } else {
                        this.scoreProfileData.memberId = this.detailInfo.member.id;
                        saveMemberProfileAPI(this.scoreProfileData).then(() => {
                          this.$message.success('保存成功');
                          this.suppleInformDialog = false;
                          this.$emit('update:flag', true)
                          this.$emit('updateDetail')
                        })
                    }
                }
            });
        },
        openSuppleInform() {
            this.suppleInformDialog = true;
        },
        clickFinalGrade(item) {
            this.finalGradeData.grade = item.value;
        },
        updateFinalGrade() {
            getDictEnumListAPI('member:grade').then((res) => {
                this.gradeList = res;
                this.finalGradeData.grade = this.detailInfo.orderScoreProfile.finalHitGrade;
                this.updateFinalGradeDialog = true;
            });
        },
        saveFinalGrade() {
            let param = {
                orderNo: this.detailInfo.orderScoreProfile.orderNo,
                grade: this.finalGradeData.grade,
                remark: this.finalGradeData.remark,
            };
            updateFinalHitGradeAPI(param).then(() => {
                this.$message.success('保存成功');
                this.$emit('update:flag', true);
                this.$emit('update:updatePeriod', false);
                this.$emit('updateDetail');
                this.updateFinalGradeDialog = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.base-info-new /deep/ {
    border-color: transparent;
    margin-top: 20px;
    width: 100%;
    border-radius: 16px;
    color: #222222;
    .fw700 {
        font-weight: 700;
    }
    .label {
        color: #666666;
        font-size: 14px;
    }
    .ivu-card {
        border-radius: 18px;
    }
    .el-dialog__footer {
        padding-bottom: 30px !important;
    }
    .base-info-top {
        display: flex;
        align-items: flex-start;
        .id-card-pic {
            margin-right: 3%;
        }
        .person-info {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            line-height: 35px;

            .mr50 {
                margin-right: 50px;
            }
            .el-tag {
                height: 25px;
                border-radius: 15px;
                margin-right: 10px;
                line-height: 25px;
            }
            .el-tag.el-tag--success {
                background-color: #fff;
                color: #666666;
                border-color: #e4e4e4;
            }
            .name {
                color: #222222;
                font-size: 18px;
                font-family: 微软雅黑;
                margin-right: 2%;
            }
            .rating {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                .edit-btn {
                    font-size: 12px;
                }
            }
        }
    }
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-style {
            color: #333333;
            font-size: 16px;
        }
        .supple-inform-edit {
            cursor: pointer;
            background: #409EFF;
            color: #FFF;
            padding: 8px 18px;
            border-radius: 45px;
        }
    }

    .tag-ku {
        padding: 4px 12px;
        border-radius: 46px;
        font-size: 12px;
        margin-right: 5px;
        color: #fff;
        .el-icon-warning {
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
    }
    // 黑名单
    .t1 {
        background-color: #d05252;
    }
    .t2 {
        background-color: #fbb438;
    }
    // 白名单
    .t3 {
        background: #4cd2a2;
    }
    .update-final-grade-dialog {
        .el-dialog__header {
            padding-top: 15px;
        }
        .el-radio-group {
            display: flex;
            justify-content: space-around;
            margin-bottom: 30px;
        }
        .final-grade-remark {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            white-space: nowrap;
        }
        .el-dialog__footer {
            padding-top: 0;
        }
    }
}
.special-card {
    background: #f7f8fa;
    line-height: 35px;
    .label {
        min-width: 120px;
        display: inline-block;
        text-align: right;
    }
    .value {
        font-weight: 600;
    }
}
</style>
